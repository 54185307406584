import { MouseEventHandler, useState, useEffect } from "react";
import type { FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import type { ListItemProps } from "@material-ui/core";
import ChevronDownIcon from "../icons/ChevronDown";
import ChevronRightIcon from "../icons/ChevronRight";
import { SIDEBAR } from "src/theme/layout";
import { matchPath } from "react-router-dom";

interface NavItemProps extends ListItemProps {
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  path?: string;
  isSubpath?: boolean;
  title: string;
  pathname?: string;
}

const pathMatch = (partial: boolean, pathname?: string, path?: string) =>
  path &&
  pathname &&
  !!matchPath(
    {
      path: path,
      end: !partial,
    },
    pathname
  );

const NavItem: FC<NavItemProps> = (props) => {
  const {
    children,
    depth,
    icon,
    path,
    title,
    pathname,
    isSubpath = false,
  } = props;
  const match = pathMatch(isSubpath || !!children, pathname, path);
  const [open, setOpen] = useState<boolean>(match);
  const [active, setActive] = useState<boolean>(match);
  useEffect(() => {
    let newMatch = pathMatch(!children, pathname, path);
    setOpen(newMatch);
    setActive(newMatch);
  }, [children, pathname, path]);

  const handleToggle: MouseEventHandler<any> = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        onClick={handleToggle}
        button
        disableGutters
        sx={{
          display: "flex",
          py: 0,
          color: "text.secondary",
          width: SIDEBAR.width,
          flexWrap: "wrap",
          overflowX: "hidden",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightBold",
            "& svg": {
              color: "primary.main",
            },
          }),
        }}
      >
        <ListItemIcon
          sx={{
            color: "text.secondary",
            pl: `${paddingLeft}px`,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            fontWeight: "fontWeightMedium",
            color: "text.secondary",
            pr: "8px",
            pl: 0,
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            flexBasis: "130px",
          }}
        >
          {title}
        </ListItemText>
        <ListItemIcon
          sx={{
            alignSelf: "right",
            color: "text.secondary",
            pl: `${paddingLeft}px`,
          }}
        >
          {open ? (
            <ChevronDownIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </ListItemIcon>
        <Collapse
          sx={{
            flexBasis: SIDEBAR.width,
          }}
          in={open}
        >
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      button
      component={path && RouterLink}
      to={path}
      disableGutters
      sx={{
        display: "flex",
        color: "text.secondary",
        py: 0,
        ...(active && {
          color: "primary.main",
          fontWeight: "fontWeightBold",
          "& svg": {
            color: "primary.main",
          },
        }),
      }}
    >
      <ListItemIcon
        sx={{
          color: "text.secondary",
          pl: `${paddingLeft}px`,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        sx={{
          fontWeight: "fontWeightMedium",
          textAlign: "left",
          pr: "8px",
          pl: "10px",
          py: "12px",
          textTransform: "none",
          width: "100%",
        }}
      >
        {title}
      </ListItemText>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  path: PropTypes.string,
  isSubpath: PropTypes.bool,
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

export default NavItem;
