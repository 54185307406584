import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, Divider } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import BriefcaseIcon from "../../icons/Briefcase";
import CalendarIcon from "../../icons/Calendar";
import ChartPieIcon from "../../icons/ChartPie";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import ChatAltIcon from "../../icons/ChatAlt";
import ClipboardListIcon from "../../icons/ClipboardList";
import FolderOpenIcon from "../../icons/FolderOpen";
import MailIcon from "../../icons/Mail";
import ShareIcon from "../../icons/Share";
import ShoppingBagIcon from "../../icons/ShoppingBag";
import ShoppingCartIcon from "../../icons/ShoppingCart";
import UserIcon from "../../icons/User";
import UsersIcon from "../../icons/Users";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { SIDEBAR } from "src/theme/layout";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: "",
    items: [
      {
        title: "Overview",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="medium" />,
      },
      {
        title: "Analytics",
        path: "/dashboard/analytics",
        icon: <ChartPieIcon fontSize="medium" />,
      },
      {
        title: "Finance",
        path: "/dashboard/finance",
        icon: <ShoppingBagIcon fontSize="medium" />,
      },
      {
        title: "Account",
        path: "/dashboard/account",
        icon: <UserIcon fontSize="medium" />,
      },
    ],
  },
  {
    title: "",
    items: [
      {
        title: "Customers",
        path: "/dashboard/customers",
        icon: <UsersIcon fontSize="medium" />,
        children: [
          {
            title: "List",
            path: "/dashboard/customers",
          },
          {
            title: "Details",
            path: "/dashboard/customers/1",
          },
          {
            title: "Edit",
            path: "/dashboard/customers/1/edit",
          },
        ],
      },
      {
        title: "Products",
        path: "/dashboard/products",
        icon: <ShoppingCartIcon fontSize="medium" />,
        children: [
          {
            title: "List",
            path: "/dashboard/products",
          },
          {
            title: "Create",
            path: "/dashboard/products/new",
          },
        ],
      },
      {
        title: "Orders",
        icon: <FolderOpenIcon fontSize="medium" />,
        path: "/dashboard/orders",
        children: [
          {
            title: "List",
            path: "/dashboard/orders",
          },
          {
            title: "Details",
            path: "/dashboard/orders/1",
          },
        ],
      },
      {
        title: "Invoices",
        path: "/dashboard/invoices",
        icon: <ReceiptIcon fontSize="medium" />,
        children: [
          {
            title: "List",
            path: "/dashboard/invoices",
          },
          {
            title: "Details",
            path: "/dashboard/invoices/1",
          },
        ],
      },
    ],
  },
  {
    title: "",
    items: [
      {
        title: "Projects",
        path: "/dashboard/projects",
        icon: <BriefcaseIcon fontSize="medium" />,
        children: [
          {
            title: "Browse",
            path: "/dashboard/projects/browse",
          },
          {
            title: "Details",
            path: "/dashboard/projects/1",
          },
          {
            title: "Create",
            path: "/dashboard/projects/new",
          },
        ],
      },
      {
        title: "Social",
        path: "/dashboard/social",
        icon: <ShareIcon fontSize="medium" />,
        children: [
          {
            title: "Profile",
            path: "/dashboard/social/profile",
          },
          {
            title: "Feed",
            path: "/dashboard/social/feed",
          },
        ],
      },
    ],
  },
  {
    title: "",
    items: [
      {
        title: "Kanban",
        path: "/dashboard/kanban",
        icon: <ClipboardListIcon fontSize="medium" />,
      },
      {
        title: "Mail",
        path: "/dashboard/mail",
        icon: <MailIcon fontSize="medium" />,
        isSubpath: true,
      },
      {
        title: "Chat",
        path: "/dashboard/chat",
        icon: <ChatAltIcon fontSize="medium" />,
      },
      {
        title: "Calendar",
        path: "/dashboard/calendar",
        icon: <CalendarIcon fontSize="medium" />,
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 0 }}>
          {sections.map((section, i) => (
            <Box>
              {i !== 0 ? <Divider /> : null}
              <NavSection
                key={i}
                pathname={location.pathname}
                collapsed={collapsed}
                sx={{
                  pb: 0,
                }}
                {...section}
              />
            </Box>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: SIDEBAR.width,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              zIndex: 2000,
              top: "64px !Important",
              width: collapsed ? SIDEBAR.collapsed.width : SIDEBAR.width,
              transition: (theme) =>
                theme.transitions.create(["width"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            },
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
